
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import distribuidorConfig from "@/core/config/DistribuidorConfig";

export default defineComponent({
  name: "auth",
  components: {},
  setup() {
		const distribuidor = distribuidorConfig[0]; 

    const url = `./distribuidor/icone_${distribuidor.distribuidor}.png`;
    const store = useStore();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });
    return{
      distribuidor,
      url
    }
  },
});
